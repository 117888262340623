import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

const NaoEncontrado = props => {
	return (
		<>
			<PageTitle title="Página não encontrada" />
		</>
	)
}

export default NaoEncontrado;